import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { GetReports, ViewReportUpdation } from "./reports.request";
import {
  setLeadExistData,
  setToastNotification,
} from "../../../../components/ToastNotification/service/toastNotificationSlice";
import { ReportsInitialStateDTO } from "./types";

export const initialState: ReportsInitialStateDTO = {
  success: false,
  error: false,
  loading: false,
  reportData: null,
  viewReportSuccess: false,
  viewReportError: false,
  viewReportLoading: false,
};

export const getReports = createAsyncThunk(
  "reports/getReports",
  async (body: any, thunkAPI: any) => {
    return GetReports(body)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const viewReports = createAsyncThunk(
  "reports/viewReports",
  async (id: string, thunkAPI: any) => {
    return ViewReportUpdation(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getReports.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getReports.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.reportData = action?.payload;
      }
    );
    builder.addCase(getReports.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(viewReports.pending, (state) => {
      state.viewReportLoading = true;
      state.viewReportSuccess = false;
      state.viewReportError = false;
    });
    builder.addCase(
      viewReports.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.viewReportLoading = false;
        state.viewReportSuccess = true;
        state.viewReportError = false;
      }
    );
    builder.addCase(viewReports.rejected, (state, action: AnyAction) => {
      state.viewReportLoading = false;
      state.viewReportSuccess = false;
      state.viewReportError = action?.error?.message || "Something went wrong";
    });
  },
});

export default reportSlice.reducer;
export const { reset } = reportSlice.actions;
