import { combineReducers } from "@reduxjs/toolkit";
import { ActionTypes } from "./actions";
import loginSlice from "../pages/auth/signin/service/signinSlice";
import forgetPasswordSlice from "../pages/auth/ForgetPassword/service/forgetPasswordSlice";
import signupSlice from "../pages/auth/signup/service/Signupslice";
import Branchslice from "../pages/app/Manage/ManageBranch/service/Branchslice";
import courseSlice from "../pages/app/Manage/ManageCourse/service/courseSlice";
import categorySlice from "../pages/app/Manage/ManageCourse/service/categorySlice";
import tagSlice from "../pages/app/Manage/ManageCourse/service/tagSlice";
import courseConfigSlice from "../pages/app/Manage/ManageCourse/service/courseConfigSlice";
import homeSlice from "../pages/app/Home/service/homeSlice";
import instituteSlice from "../pages/app/Manage/InstituteProfile/service/instituteSlice";
import Staffslice from "../pages/app/Manage/ManageStaff/service/Staffslice";
import Batchslice from "../pages/app/Manage/ManageBatch/service/Batchslice";
import activeInstituteSlice from "../service/activeInstituteSlice";
import nurtureSlice from "../pages/app/Nurture/service/nurtureSlice";
import studentSlice from "../pages/app/Manage/ManageStudent/service/studentSlice";
import fileUploadSlice from "../pages/app/Main/components/FileUpload/service/fileUploadSlice";
import inboundSlice from "../pages/app/Inbound/service/inboundSlice";
import dashboardSlice from "../pages/app/Dashboard/service/dashboardSlice";
import tasksSlice from "../pages/app/Tasks/service/tasksSlice";
import userDetailsSlice from "../pages/app/Manage/UserDetails/service/userDetailsSlice";
import profileReducer from "../pages/app/Main/service/profileReducer";
import billingSlice from "../pages/app/Manage/Billing/service/Billingslice";
import deactivateInboundSlice from "../pages/app/Inbound/service/deactivateInboundSlice";
import inboundImapSlice from "../pages/app/Inbound/service/inboundImapSlice";
import importSlice from "../pages/app/Inbound/service/importSlice";
import campaignSlice from "../pages/app/campaign/service/campaignSlice";
import notificationSlice from "../pages/app/Manage/Notification/service/Notificationslice";
import instituteProfileReducer from "../pages/app/Main/service/instituteReducer";
import tncSlice from "../pages/app/TermsOfServices/service/termsofservicesSlice";
import inboundImportSlice from "../pages/app/Inbound/service/inboundImportSlice";
import whatsappSlice from "../pages/app/Inbound/Whatsapp/service/whatsappSlice";
import webformSlice from "../pages/app/Inbound/Webform/service/webformSlice";
import publicwebformSlice from "../pages/app/Inbound/Webform/publicwebform/service/publicwebformSlice";
import IntegrationsSlice from "../pages/app/Integrations/service/IntegrationsSlice";
import toastNotificationSlice from "../components/ToastNotification/service/toastNotificationSlice";
import websocketNotificationsSlice from "../service/websocketNotificationsSlice";
import settingsSlice from "../pages/app/Settings/service/settingsSlice";
import attendanceSlice from "../pages/app/Attendance/service/attendanceSlice";

import compressReducer from "../../src/pages/app/Main/service/compressorSlice";
import reportReducer from "../../src/pages/app/Reports/service/reportsSlice";
import reportCreationReducer from "../../src/pages/app/Reports/service/reportCreationSlice";

const appReducer = combineReducers({
  login: loginSlice,
  forgetPassword: forgetPasswordSlice,
  signUp: signupSlice,
  branch: Branchslice,
  courses: courseSlice,
  category: categorySlice,
  tag: tagSlice,
  courseConfig: courseConfigSlice,
  home: homeSlice,
  institute: instituteSlice,
  staff: Staffslice,
  batch: Batchslice,
  activeInstitute: activeInstituteSlice,
  nurture: nurtureSlice,
  student: studentSlice,
  fileUpload: fileUploadSlice,
  inbound: inboundSlice,
  dashboard: dashboardSlice,
  tasks: tasksSlice,
  userDetails: userDetailsSlice,
  profile: profileReducer,
  billing: billingSlice,
  settings: deactivateInboundSlice,
  imap: inboundImapSlice,
  importData: importSlice,
  campaign: campaignSlice,
  notification: notificationSlice,
  instituteProfile: instituteProfileReducer,
  tnc: tncSlice,
  import: inboundImportSlice,
  whatsapp: whatsappSlice,
  webform: webformSlice,
  publicWebform: publicwebformSlice,
  appIntegrations: IntegrationsSlice,
  toastNotification: toastNotificationSlice,
  websocketNotifications: websocketNotificationsSlice,
  appSettings: settingsSlice,
  attendance: attendanceSlice,
  compress: compressReducer,
  reports: reportReducer,
  reportCreation: reportCreationReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === ActionTypes.RESET_STATE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
