import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  DownloadReport,
  GetDefaultReportData,
  SaveReport,
} from "./reports.request";
import {
  setLeadExistData,
  setToastNotification,
} from "../../../../components/ToastNotification/service/toastNotificationSlice";
import { ReportCreationInitialStateDTO } from "./types";

export const initialState: ReportCreationInitialStateDTO = {
  getDefaultReportDataLoading: false,
  getDefaultReportDataSuccess: false,
  getDefaultReportDataError: false,
  getDefaultReportData: null,
  saveReportLoading: false,
  saveReportSuccess: false,
  saveReportError: false,
  downloadReportLoading: false,
  downloadReportSuccess: false,
  downloadReportError: false,
  downloadReportData: null,
};

export const getDefaultReportData = createAsyncThunk(
  "reports/getDefaultReportData",
  async (body: any, thunkAPI: any) => {
    return GetDefaultReportData(body)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const saveReport = createAsyncThunk(
  "reports/saveReport",
  async (body: any, thunkAPI: any) => {
    return SaveReport(body)
      .then((response: any) => {
        // if (response) {
        //   thunkAPI.dispatch(
        //     setToastNotification({
        //       message: response?.data?.message,
        //       type: "success",
        //       snackOpen: true,
        //     })
        //   );
        // }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getDownloadedReport = createAsyncThunk(
  "reports/getDownloadedReport",
  async (body: any, thunkAPI: any) => {
    return DownloadReport(body)
      .then((response: any) => {
        thunkAPI.dispatch(
          setToastNotification({
            message: "Congratulations! Report is successfully downloaded.",
            type: "success",
            snackOpen: true,
          })
        );
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const reportCreationSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    reset: () => initialState,
    resetSaveReportSuccess: (state) => {
      state.saveReportSuccess = false;
    },
    resetDownloadReportSuccess: (state) => {
      state.downloadReportSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDefaultReportData.pending, (state) => {
      state.getDefaultReportDataLoading = true;
      state.getDefaultReportDataSuccess = false;
      state.getDefaultReportDataError = false;
    });
    builder.addCase(
      getDefaultReportData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getDefaultReportDataLoading = false;
        state.getDefaultReportDataSuccess = true;
        state.getDefaultReportDataError = false;
        state.getDefaultReportData = action?.payload;
      }
    );
    builder.addCase(
      getDefaultReportData.rejected,
      (state, action: AnyAction) => {
        state.getDefaultReportDataLoading = false;
        state.getDefaultReportDataSuccess = false;
        state.getDefaultReportDataError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(saveReport.pending, (state) => {
      state.saveReportLoading = true;
      state.saveReportSuccess = false;
      state.saveReportError = false;
    });
    builder.addCase(
      saveReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.saveReportLoading = false;
        state.saveReportSuccess = true;
        state.saveReportError = false;
      }
    );
    builder.addCase(saveReport.rejected, (state, action: AnyAction) => {
      state.saveReportLoading = false;
      state.saveReportSuccess = false;
      state.saveReportError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getDownloadedReport.pending, (state) => {
      state.downloadReportLoading = true;
      state.downloadReportSuccess = false;
      state.downloadReportError = false;
    });
    builder.addCase(
      getDownloadedReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.downloadReportLoading = false;
        state.downloadReportSuccess = true;
        state.downloadReportError = false;
        state.downloadReportData = action?.payload;
      }
    );
    builder.addCase(
      getDownloadedReport.rejected,
      (state, action: AnyAction) => {
        state.downloadReportLoading = false;
        state.downloadReportSuccess = false;
        state.downloadReportError =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default reportCreationSlice.reducer;
export const { reset, resetSaveReportSuccess, resetDownloadReportSuccess } =
  reportCreationSlice.actions;
