// features/notifications/notificationsSlice.ts
import {
  createSlice,
  PayloadAction,
  createAction,
  AnyAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import {
  ClearPaymentReminder,
  ClearAllPaymentReminder,
  GetPaymentReminder,
  GetPaymentReminderCount,
} from "../pages/app/Nurture/service/nurture.request";

interface Notification {
  id: string;
  message: string;
  taskReminders: any;
  paymentRemindersLoading: boolean;
  paymentRemindersSuccess: boolean;
  paymentRemindersError: boolean;
  paymentReminderCountLoading: boolean;
  paymentReminderCountSuccess: boolean;
  paymentReminderCountError: boolean;
  paymentReminderList: any;
  paymentReminderCount: any;
  clearPaymentReminderLoading: boolean;
  clearPaymentReminderSuccess: boolean;
  clearPaymentReminderError: boolean;
  clearAllPaymentReminderLoading: boolean;
  clearAllPaymentReminderSuccess: boolean;
  clearAllPaymentReminderError: boolean;
  leadUpdateNotify: boolean;
  getLatestReports: boolean;
}

// const initialState: Notification[] = [];
export const initialState: Notification = {
  id: "",
  message: "",
  taskReminders: null,
  paymentReminderList: null,
  paymentReminderCount: null,
  leadUpdateNotify: false,
  paymentRemindersLoading: false,
  paymentRemindersSuccess: false,
  paymentRemindersError: false,
  paymentReminderCountLoading: false,
  paymentReminderCountSuccess: false,
  paymentReminderCountError: false,

  clearPaymentReminderLoading: false,
  clearPaymentReminderSuccess: false,
  clearPaymentReminderError: false,
  clearAllPaymentReminderLoading: false,
  clearAllPaymentReminderSuccess: false,
  clearAllPaymentReminderError: false,

  getLatestReports: false,
};

export const notificationReceived = createAction<Notification>(
  "websocketNotifications/received"
);

export const getPaymentReminder = createAsyncThunk(
  "/user/getPaymentReminder",
  async (
    data: {
      userId: string;
      lastEvaluatedKey: string;
      limit: number;
      lastEvaluatedSortKey: string;
    },
    thunkAPI: any
  ) => {
    const { userId, lastEvaluatedKey, limit, lastEvaluatedSortKey } = data;

    return GetPaymentReminder(
      userId,
      lastEvaluatedKey,
      limit,
      lastEvaluatedSortKey
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getPaymentReminderCount = createAsyncThunk(
  "/user/getPaymentReminderCount",
  async (
    data: {
      userId: string;
    },
    thunkAPI: any
  ) => {
    const { userId } = data;

    return GetPaymentReminderCount(userId)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const clearPaymentReminder = createAsyncThunk(
  "user/clearPaymentReminder",
  async (data: string, thunkAPI: any) => {
    return ClearPaymentReminder(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const clearAllPaymentReminder = createAsyncThunk(
  "user/clearAllPaymentReminder",
  async (data: string, thunkAPI: any) => {
    return ClearAllPaymentReminder(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

const websocketNotificationsSlice = createSlice({
  name: "websocketNotifications",
  initialState,
  reducers: {
    reset: () => initialState,
    setTaskReminders: (state, action) => {
      const data = action.payload;

      // state.taskReminders = data;
      if (state.taskReminders === null || state.taskReminders === undefined) {
        state.taskReminders = [];
      }
      // Push the new task reminder data to the array
      state.taskReminders.push(data);
    },
    // resetTaskReminders: (state, action) => {
    //   const data = action.payload;
    //   state.taskReminders === action.payload;
    // },
    resetTaskReminders: (state, action: PayloadAction<Notification[]>) => {
      state.taskReminders = action.payload;
    },
    clearTaskReminders: (state) => {
      state.taskReminders = null;
    },
    setLeadBoardRefresh: (state, action) => {
      if (action.payload === "REFRESH_BOARD") {
        state.leadUpdateNotify = true;
      }
    },
    clearLeadBoardRefresh: (state) => {
      state.leadUpdateNotify = false;
    },

    setGetLatestReports: (state, action) => {
      if (action.payload === "REFRESH_REPORT") {
        state.getLatestReports = true;
      }
    },
    clearGetLatestReports: (state) => {
      state.getLatestReports = false;
    },
  },

  extraReducers: (builder) => {
    // builder.addCase(notificationReceived, (state, action) => {
    //   state.paymentRemindersLoading = true;
    //   state.paymentRemindersSuccess = false;
    //   state.paymentRemindersError = false;
    // });

    builder.addCase(
      getPaymentReminder.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.paymentRemindersLoading = false;
        state.paymentRemindersSuccess = true;
        state.paymentRemindersError = false;
        state.paymentReminderList = action?.payload;
      }
    );
    builder.addCase(getPaymentReminder.rejected, (state, action: AnyAction) => {
      state.paymentRemindersLoading = false;
      state.paymentRemindersSuccess = false;
      state.paymentRemindersError = true;
    });
    builder.addCase(getPaymentReminder.pending, (state) => {
      state.paymentRemindersLoading = true;
      state.paymentRemindersSuccess = false;
      state.paymentRemindersError = false;
    });
    builder.addCase(
      getPaymentReminderCount.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.paymentReminderCountLoading = false;
        state.paymentReminderCountSuccess = true;
        state.paymentReminderCountError = false;
        state.paymentReminderCount = action?.payload;
      }
    );
    builder.addCase(
      getPaymentReminderCount.rejected,
      (state, action: AnyAction) => {
        state.paymentReminderCountLoading = false;
        state.paymentReminderCountSuccess = false;
        state.paymentReminderCountError = true;
      }
    );
    builder.addCase(getPaymentReminderCount.pending, (state) => {
      state.paymentReminderCountLoading = true;
      state.paymentReminderCountSuccess = false;
      state.paymentReminderCountError = false;
    });
    builder.addCase(clearPaymentReminder.pending, (state) => {
      state.clearPaymentReminderLoading = true;
      state.clearPaymentReminderSuccess = false;
      state.clearPaymentReminderError = false;
    });
    builder.addCase(
      clearPaymentReminder.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.clearPaymentReminderLoading = false;
        state.clearPaymentReminderSuccess = true;
        state.clearPaymentReminderError = false;
      }
    );
    builder.addCase(
      clearPaymentReminder.rejected,
      (state, action: AnyAction) => {
        state.clearPaymentReminderLoading = false;
        state.clearPaymentReminderSuccess = false;
        state.clearPaymentReminderError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(clearAllPaymentReminder.pending, (state) => {
      state.clearAllPaymentReminderLoading = true;
      state.clearAllPaymentReminderSuccess = false;
      state.clearAllPaymentReminderError = false;
    });
    builder.addCase(
      clearAllPaymentReminder.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.clearAllPaymentReminderLoading = false;
        state.clearAllPaymentReminderSuccess = true;
        state.clearAllPaymentReminderError = false;
      }
    );
    builder.addCase(
      clearAllPaymentReminder.rejected,
      (state, action: AnyAction) => {
        state.clearAllPaymentReminderLoading = false;
        state.clearAllPaymentReminderSuccess = false;
        state.clearAllPaymentReminderError =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default websocketNotificationsSlice.reducer;
export const {
  reset,
  setTaskReminders,
  resetTaskReminders,
  clearTaskReminders,
  setLeadBoardRefresh,
  clearLeadBoardRefresh,
  setGetLatestReports,
  clearGetLatestReports,
} = websocketNotificationsSlice.actions;
