import {
  axios_institute_private,
  axios_openSearch,
} from "../../../../api/setup.intersepter";

export const GetReports = (body: any) => {
  return axios_openSearch({
    method: "POST",
    url: `/elastic_search/nurtureSearch`,
    data: body,
  });
};

export const GetDefaultReportData = (body: any) => {
  return axios_openSearch({
    method: "POST",
    url: `/elastic_search/nurtureSearch`,
    data: body,
  });
};

export const SaveReport = (body: any) => {
  return axios_institute_private({
    method: "POST",
    url: `/report`,
    data: body,
  });
};

export const ViewReportUpdation = (id: string) => {
  return axios_institute_private({
    method: "PUT",
    url: `/report?reportId=${id}`,
  });
};

export const DownloadReport = (body: any) => {
  return axios_institute_private({
    method: "POST",
    url: `/report`,
    data: body,
  });
};
